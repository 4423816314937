import styled from "styled-components";

export const TabWrapper = styled.div`
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 30px 15px 105px;
	@media(min-width: 400px) {
		padding: 30px 30px 105px;
	}
	@media(min-width: 768px) {
		padding: 80px 34px 100px;
	}
	@media(min-width: 1024px) {
		width: 100%;
	} 
	@media(min-width: 1280px) {
		padding: 100px 30px 120px 40px;
	}
	@media(min-width: 1920px) {
		padding: 100px 43px 145px 75px;
	}
`

export const BlueHint = styled.p`
	font-size: 15px;
	font-weight: ${({theme}) => theme.fontWeight.regular};
	line-height: 26px;
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	text-align: center;
	&.section-title {
		text-align: left;
		margin: 0;
	}
	&.tablet-hidden {
		display: none;
		@media(min-width: 1024px) {
			display: flex;
		}
	}
	@media(min-width: 768px) {
		font-size: 22px;
		line-height: 38px;
		margin-bottom: 70px;
	}
	@media(min-width: 1024px) {
		font-weight: ${({theme}) => theme.fontWeight.light};
		text-align: left;
		margin-bottom: 0;
	}
	@media(min-width: 1280px) {
    	font-size: 22px;
		line-height: 40px;
	}
`

export const TiltsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
	flex-wrap: wrap;
	div {
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@media(min-width: 414px) {
		justify-content: flex-start;
		div.tilt {
			&:nth-child(2n) {
				margin-right: 16px;
			}
			&:nth-child(3n) {
				//margin-right: 0;
			}
		}
	}
	@media(min-width: 536px) {
		div.tilt {
			&:nth-child(3n) {
				margin-right: 16px;
			}
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}
	@media(min-width: 650px) {
		div.tilt {
			&:nth-child(4n) {
				margin-right: 16px;
			}
		}
	}
	
	@media(min-width: 768px) {
		justify-content: flex-start;
		padding: 0;
		div.tilt {
			&:nth-child(2n) {
				margin-right: 20px;
			}
			&:nth-child(3n) {
				margin-right: 20px;
			}
			&:nth-child(4n) {
				margin-right: unset;
			}
		}
	}
	@media(min-width: 945px) {
		div.tilt {
			&:nth-child(4n) {
				margin-right: 20px;
			}
		}
	}
	@media(min-width: 1024px) {
		padding-right: 35px;
		div.tilt {
			&:nth-child(4n) {
				margin-right: 20px;
			}
		}
	}
	@media(min-width: 1150px) {
		padding: 0;
	}
	@media(min-width: 1920px) {
		div.tilt {
			&:nth-child(2n) {
				margin-right: 20px;
			}
			&:nth-child(3n) {
				margin-right: 20px;
			}
			&:nth-child(4n) {
				margin-right: 20px;
			}
		}
	}
	&.fuel-types {
		@media(min-width: 768px) {
			padding: 0;
		}
		@media(min-width: 1280px) {
			justify-content: flex-start;
		}
	}
`

export const ErrorMessageText = styled.p`
	font-size: 16px;
	color: ${({theme}) => theme.colors.formAlwisOrange};
	position: absolute;
	bottom: -20px;
	// margin-top: ${({isMovedTop}) => isMovedTop ? "-40px" : "0"};	
`;


export const RadioButtonsTwoOptionsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	&.margin-bottom {
		margin-bottom: -30px;
	}
	div {
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@media(min-width: 414px) {
		max-width: 353px;
	}
`

export const HeaderAndRadioButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 20px 0 5px;
	@media(min-width: 1280px) {
		margin: 30px 0 15px;
	}
	&.first {
		margin-top: 0;
	}
`;

export const RadioButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 10px;
	//padding-right: 10%;
	@media(min-width: 390px) {
		//padding-right: 16%;
	}
	@media(min-width: 414px) {
		padding-right: 0;
	}
	@media(min-width: 768px) {
		padding-right: 10%;
	}
	@media(min-width: 1024px) {
		margin-top: 15px;
	}
	@media(min-width: 1920px) {
		margin-top: 0;
	}
`

export const SingleRadioButtonWrapper = styled.div`
	margin: 30px 0 -10px;
	position: relative;
	@media(min-width: 1280px) {
		margin: 50px 0 0px;
		&.second-item {
			margin: 0 0 -20px;
		}
	}
	&.second-item {
		margin: 0 0 -30px;
		p {
			bottom: 25px;
		}
	}
`

export const InvisibleWrapper = styled.div`
	display: ${({isVisible}) => isVisible ? 'block' : 'none'};
`

export const currentDate = new Date();
export const calendarDateFormat = "dd/MM/yyyy";

export const HeaderAndQuestionMarkWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	&.move-top {
		margin-top: -30px;
	}
`
