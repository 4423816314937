import React from "react"
import styled from "styled-components"

export const NavigationButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 70px;
	&.wrap-button {
	  flex-wrap: wrap;
	}
	@media(min-width: 1280px) {
		margin-top: 100px;
		justify-content: center;
		margin-left: -10px;
	}
	@media(min-width: 1920px) {
		margin-top: 60px;
		margin-left: -25px;
	}
	&.whole-margin {
		@media(min-width: 1920px) {
			margin-top: 110px;
		}
	}
`

