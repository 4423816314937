import React from "react"
import styled from "styled-components"

const BasicButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: ${({isVisible}) => isVisible ? "visible" : "hidden"};
	a {
		text-decoration: none;
	}
`;

const ButtonText = styled.p`
	font-size: 16px;
	font-weight: ${({theme}) => theme.fontWeight.black};
	line-height: 20px;
	color: ${({theme}) => theme.colors.formWhite};
	text-transform: uppercase;
	@media(min-width: 768px) {
		font-size: 21px;
		line-height: 27px;
	}
`
const BasicButtonStyled = styled.button`
	background-color: ${({theme}) => theme.colors.formAlwisOrange};
	border: 1px solid ${({theme}) => theme.colors.formDarkOrange};
	padding: 17px 15px;
	border-radius: 30px;
	cursor: pointer;
	flex-direction: row;
	justify-content: center;
	display: flex;
	width: 120px;
	@media(min-width: 360px) {
		width: 140px;
		padding: 17px 20px;
	}
	@media(min-width: 768px) {
		min-width: 180px;
		padding: 16px 20px 15px 20px;
	}
	&.send-form {
		min-width: 310px
	}
`;

const NextStepButton = (props) => {
	const {textBtn, isVisible, type, isDisabled, className} = props;

	return (
		<BasicButtonWrapper isVisible={isVisible}>
			<BasicButtonStyled className={className} type={type} disabled={isDisabled}>
				<ButtonText>{textBtn}</ButtonText>
			</BasicButtonStyled>
		</BasicButtonWrapper>
	)
};

export default NextStepButton;
