import React from "react"
import styled from "styled-components"
import QuestionMark from "../../images/OtherAssets/questionMark.svg"
import Tooltip from "./Tooltip"

const CircleWrapper = styled.div`
  display: ${({ showCircle }) => (showCircle ? "flex" : "none")};
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.formWhite};
  border: 1px solid ${({ theme }) => theme.colors.formBlue};
  cursor: pointer;
  margin-left: ${({ isRadioButton }) => (isRadioButton ? "-20px" : "20px")};
  margin-top: ${({ isMarginTop, isRadioButton }) =>
          isMarginTop ? "40px" : isRadioButton ? "15px" : "0"};
  @media (min-width: 768px) {
    position: relative;
  }
`

const Icon = styled.img`
  height: 12px;
  @media (min-width: 768px) {
    height: 10px;
  }
`

const CircleWithQuestionMark = ({
	                                showCircle,
	                                onClick,
	                                isWindowVisible,
	                                tooltipHeader,
	                                tooltipDescription,
	                                isMarginTop,
	                                isRadioButton,
	                                isFirstOnPage,
                                }) => {
	return (
		<CircleWrapper
			showCircle={showCircle}
			onClick={onClick}
			isMarginTop={isMarginTop}
			isRadioButton={isRadioButton}
		>
			<Icon src={QuestionMark} />
			{isWindowVisible && (
				<Tooltip
					isFirstOnPage={isFirstOnPage}
					onClick={onClick}
					tooltipHeader={tooltipHeader}
					tooltipDescription={tooltipDescription}
				/>
			)}
		</CircleWrapper>
	)
}

export default CircleWithQuestionMark
