import React from "react"
import styled from "styled-components"
import XIcon from "../../images/OtherAssets/x-icon.svg"

const InfoWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 150000000;
  //width: 480px;
  background-color: ${({ theme }) => theme.colors.formWhite};
  border: 1px solid ${({ theme }) => theme.colors.formBlue};
  border-radius: 25px;
  position: absolute;
  left: 0;
  top: 0;
    // top: ${({ isFirstOnPage }) => (isFirstOnPage ? "90px" : "0")};
  //transform: translateY(-50%);
  transform: ${({ isFirstOnPage }) =>
          isFirstOnPage ? "translateY(-20%)" : "translateY(-50%)"};
  margin: 0 auto;
  padding: 40px 30px 55px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.13) !important;
  max-height: 55vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media(min-width: 320px) {

    transform:translateY(25px);
    position: absolute;
  }
  @media(min-width: 414px) {
    max-width: 353px;
    overflow: unset;

  }
  @media(min-width: 536px) {
    max-width: 476px;
    overflow: unset;
  }
  @media(min-width: 768px) {
    max-width: 480px;
    left:35px;
    transform: translateY(-49%);

  }
  @media(min-width: 1024px) {
    left: 35px;

    max-height: 75vh;
    margin: 0 auto;
    max-width: 380px;

  }
  @media(min-width: 1280px) {
    left: 35px;
    max-width: 440px;

  }
  @media(min-width: 1600px) {
    max-width: 520px;
  }

  &::before {
    content: '';
    visibility:visible;
    position: absolute;
    top: 50%;
    left: -7px;
    height: 12px;
    width: 12px;
    background: #FFF;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-left: 1px solid #BFDEFF;
    border-bottom: 1px solid #BFDEFF;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    z-index: 1000;
    @media(max-width: 768px) {
      visibility:hidden;
      top: -7px;
      left: 188px;
      transform: rotate(135deg);

    }
  }

`

const Header = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.formFontDarkBlue};
  font-family: ${({ theme }) => theme.fontFamily.mainFont};
  position: relative;
  text-align: left;
  padding-bottom: 20px;
  //width: 480px;
  width: 100%;
  font-size: 20px;
  line-height: 38px;
  @media (min-width: 768px) {
    padding-bottom: 20px;
    width: 480px;
  }
`

const Description = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.formFontDarkBlue};
  font-family: ${({ theme }) => theme.fontFamily.mainFont};
  position: relative;
  text-align: left;
  span {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
`

const CloseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.formAlwisOrange};
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  z-index: 2000;
  width: 21px;
  height: 21px;
  top: 20px;
  right: 20px;
`

const CloseImage = styled.img`
  width: 7px;
  margin: 0 auto;
`

const Tooltip = ({
	                 onClick,
	                 tooltipHeader,
	                 tooltipDescription,
	                 isFirstOnPage,
                 }) => {
	return (
		<InfoWrapper key="tooltip" isFirstOnPage={isFirstOnPage}>
			<CloseWrapper onClick={onClick}>
				<CloseImage src={XIcon} />
			</CloseWrapper>
			<Header>{tooltipHeader}</Header>
			<Description>{tooltipDescription}</Description>
		</InfoWrapper>
	)
}

export default Tooltip
