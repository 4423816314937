import React from "react"
import styled from "styled-components"

export const CategoryHeader = styled.h3`
	font-size: 16px;
	font-weight: ${({theme}) => theme.fontWeight.black};
	line-height: 20px;
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	margin-bottom: 8px;
	z-index: 0;
	//min-width: 280px;
	&.radio-buttons {
		margin-right: 40px;
		margin-top: 15px;
	}
	&.with-checkbox {
		margin-bottom: 0;
	}
	&.fuel-type {
		margin-top: 50px;
	}
	&.margin-top {
		margin-top: 40px;
	}
	&.gender, &.is-narrow {
		min-width: unset;
		margin-right: 40px;
	}
	@media(min-width: 536px) {
		max-width: 476px;
	}
	@media(min-width: 1280px) {
		font-size: 14px;
		line-height: 18px;
		max-width: 520px;
	}
	@media(min-width: 1920px) {
		font-size: 16px;
		line-height: 20px;
		max-width: 550px;
	}
`;
